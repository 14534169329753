
var ortWasmThreaded = (() => {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  if (typeof __filename !== 'undefined') _scriptDir = _scriptDir || __filename;
  return (
function(ortWasmThreaded) {
  ortWasmThreaded = ortWasmThreaded || {};


function d(){m.buffer!=n&&p(m.buffer);return aa}function q(){m.buffer!=n&&p(m.buffer);return ba}function r(){m.buffer!=n&&p(m.buffer);return ca}function v(){m.buffer!=n&&p(m.buffer);return da}function ea(){m.buffer!=n&&p(m.buffer);return fa}var x;x||(x=typeof ortWasmThreaded !== 'undefined' ? ortWasmThreaded : {});var ha,ia;x.ready=new Promise(function(a,b){ha=a;ia=b});
var ja=Object.assign({},x),ka="./this.program",la=(a,b)=>{throw b;},ma="object"==typeof window,y="function"==typeof importScripts,B="object"==typeof process&&"object"==typeof process.versions&&"string"==typeof process.versions.node,C=x.ENVIRONMENT_IS_PTHREAD||!1,D="";function na(a){return x.locateFile?x.locateFile(a,D):D+a}var oa,pa,qa,fs,ra,sa;
if(B){D=y?require("path").dirname(D)+"/":__dirname+"/";sa=()=>{ra||(fs=require("fs"),ra=require("path"))};oa=function(b,c){sa();b=ra.normalize(b);return fs.readFileSync(b,c?void 0:"utf8")};qa=b=>{b=oa(b,!0);b.buffer||(b=new Uint8Array(b));return b};pa=(b,c,e)=>{sa();b=ra.normalize(b);fs.readFile(b,function(f,h){f?e(f):c(h.buffer)})};1<process.argv.length&&(ka=process.argv[1].replace(/\\/g,"/"));process.argv.slice(2);process.on("uncaughtException",function(b){if(!(b instanceof E))throw b;});process.on("unhandledRejection",
function(b){throw b;});la=(b,c)=>{if(F())throw process.exitCode=b,c;c instanceof E||G("exiting due to exception: "+c);process.exit(b)};x.inspect=function(){return"[Emscripten Module object]"};let a;try{a=require("worker_threads")}catch(b){throw console.error('The "worker_threads" module is not supported in this node.js build - perhaps a newer version is needed?'),b;}global.Worker=a.Worker}else if(ma||y)y?D=self.location.href:"undefined"!=typeof document&&document.currentScript&&(D=document.currentScript.src),
_scriptDir&&(D=_scriptDir),0!==D.indexOf("blob:")?D=D.substr(0,D.replace(/[?#].*/,"").lastIndexOf("/")+1):D="",B||(oa=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.send(null);return b.responseText},y&&(qa=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.responseType="arraybuffer";b.send(null);return new Uint8Array(b.response)}),pa=(a,b,c)=>{var e=new XMLHttpRequest;e.open("GET",a,!0);e.responseType="arraybuffer";e.onload=()=>{200==e.status||0==e.status&&e.response?b(e.response):c()};e.onerror=
c;e.send(null)});B&&"undefined"==typeof performance&&(global.performance=require("perf_hooks").performance);var ta=console.log.bind(console),ua=console.warn.bind(console);B&&(sa(),ta=a=>fs.writeSync(1,a+"\n"),ua=a=>fs.writeSync(2,a+"\n"));var va=x.print||ta,G=x.printErr||ua;Object.assign(x,ja);ja=null;x.thisProgram&&(ka=x.thisProgram);x.quit&&(la=x.quit);var H;x.wasmBinary&&(H=x.wasmBinary);var noExitRuntime=x.noExitRuntime||!1;"object"!=typeof WebAssembly&&I("no native wasm support detected");
var m,wa,xa=!1,ya="undefined"!=typeof TextDecoder?new TextDecoder("utf8"):void 0;
function za(a,b,c){b>>>=0;var e=b+c;for(c=b;a[c]&&!(c>=e);)++c;if(16<c-b&&a.buffer&&ya)return ya.decode(a.buffer instanceof SharedArrayBuffer?a.slice(b,c):a.subarray(b,c));for(e="";b<c;){var f=a[b++];if(f&128){var h=a[b++]&63;if(192==(f&224))e+=String.fromCharCode((f&31)<<6|h);else{var k=a[b++]&63;f=224==(f&240)?(f&15)<<12|h<<6|k:(f&7)<<18|h<<12|k<<6|a[b++]&63;65536>f?e+=String.fromCharCode(f):(f-=65536,e+=String.fromCharCode(55296|f>>10,56320|f&1023))}}else e+=String.fromCharCode(f)}return e}
function Aa(a,b){return(a>>>=0)?za(q(),a,b):""}
function Ba(a,b,c,e){c>>>=0;if(!(0<e))return 0;var f=c;e=c+e-1;for(var h=0;h<a.length;++h){var k=a.charCodeAt(h);if(55296<=k&&57343>=k){var l=a.charCodeAt(++h);k=65536+((k&1023)<<10)|l&1023}if(127>=k){if(c>=e)break;b[c++>>>0]=k}else{if(2047>=k){if(c+1>=e)break;b[c++>>>0]=192|k>>6}else{if(65535>=k){if(c+2>=e)break;b[c++>>>0]=224|k>>12}else{if(c+3>=e)break;b[c++>>>0]=240|k>>18;b[c++>>>0]=128|k>>12&63}b[c++>>>0]=128|k>>6&63}b[c++>>>0]=128|k&63}}b[c>>>0]=0;return c-f}
function Ca(a){for(var b=0,c=0;c<a.length;++c){var e=a.charCodeAt(c);127>=e?b++:2047>=e?b+=2:55296<=e&&57343>=e?(b+=4,++c):b+=3}return b}var n,aa,ba,ca,da,fa;C&&(n=x.buffer);function p(a){n=a;x.HEAP8=aa=new Int8Array(a);x.HEAP16=new Int16Array(a);x.HEAP32=ca=new Int32Array(a);x.HEAPU8=ba=new Uint8Array(a);x.HEAPU16=new Uint16Array(a);x.HEAPU32=da=new Uint32Array(a);x.HEAPF32=new Float32Array(a);x.HEAPF64=fa=new Float64Array(a)}var Da=x.INITIAL_MEMORY||16777216;
if(C)m=x.wasmMemory,n=x.buffer;else if(x.wasmMemory)m=x.wasmMemory;else if(m=new WebAssembly.Memory({initial:Da/65536,maximum:65536,shared:!0}),!(m.buffer instanceof SharedArrayBuffer))throw G("requested a shared WebAssembly.Memory but the returned buffer is not a SharedArrayBuffer, indicating that while the browser has SharedArrayBuffer it does not have WebAssembly threads support - you may need to set a flag"),B&&console.log("(on node you may need: --experimental-wasm-threads --experimental-wasm-bulk-memory and also use a recent version)"),
Error("bad memory");m&&(n=m.buffer);Da=n.byteLength;p(n);var Ea,Fa=[],Ga=[],Ha=[],Ia=[],Ja=0;function F(){return noExitRuntime||0<Ja}function Ka(){var a=x.preRun.shift();Fa.unshift(a)}var L=0,La=null,M=null;function I(a){if(C)postMessage({cmd:"onAbort",arg:a});else if(x.onAbort)x.onAbort(a);a="Aborted("+a+")";G(a);xa=!0;a=new WebAssembly.RuntimeError(a+". Build with -sASSERTIONS for more info.");ia(a);throw a;}function Ma(){return O.startsWith("data:application/octet-stream;base64,")}var O;O="ort-wasm-threaded.wasm";
Ma()||(O=na(O));function Na(){var a=O;try{if(a==O&&H)return new Uint8Array(H);if(qa)return qa(a);throw"both async and sync fetching of the wasm failed";}catch(b){I(b)}}
function Oa(){if(!H&&(ma||y)){if("function"==typeof fetch&&!O.startsWith("file://"))return fetch(O,{credentials:"same-origin"}).then(function(a){if(!a.ok)throw"failed to load wasm binary file at '"+O+"'";return a.arrayBuffer()}).catch(function(){return Na()});if(pa)return new Promise(function(a,b){pa(O,function(c){a(new Uint8Array(c))},b)})}return Promise.resolve().then(function(){return Na()})}var Pa={};
function E(a){this.name="ExitStatus";this.message="Program terminated with exit("+a+")";this.status=a}function Qa(a){(a=P.Vb[a])||I();P.mc(a)}function Ra(a){var b=P.Cc();if(!b)return 6;P.ac.push(b);P.Vb[a.Ub]=b;b.Ub=a.Ub;var c={cmd:"run",start_routine:a.Ic,arg:a.zc,pthread_ptr:a.Ub};b.$b=()=>{c.time=performance.now();b.postMessage(c,a.Nc)};b.loaded&&(b.$b(),delete b.$b);return 0}function Sa(a){if(C)return Q(1,1,a);if(!F()){P.oc();if(x.onExit)x.onExit(a);xa=!0}la(a,new E(a))}
function Ta(a,b){if(!b&&C)throw Ua(a),"unwind";F()||C||(Va(),R(Ha),Wa(0),Xa[1].length&&Ya(1,10),Xa[2].length&&Ya(2,10),P.oc());Sa(a)}
var P={Yb:[],ac:[],qc:[],Vb:{},fc:function(){C&&P.Ec()},Pc:function(){},Ec:function(){P.receiveObjectTransfer=P.Gc;P.threadInitTLS=P.pc;P.setExitStatus=P.nc;noExitRuntime=!1},nc:function(){},oc:function(){for(var a of Object.values(P.Vb))P.mc(a);for(a of P.Yb)a.terminate();P.Yb=[]},mc:function(a){var b=a.Ub;delete P.Vb[b];P.Yb.push(a);P.ac.splice(P.ac.indexOf(a),1);a.Ub=0;Za(b)},Gc:function(){},pc:function(){P.qc.forEach(a=>a())},Fc:function(a,b){a.onmessage=c=>{c=c.data;var e=c.cmd;a.Ub&&(P.Bc=a.Ub);
if(c.targetThread&&c.targetThread!=$a()){var f=P.Vb[c.Qc];f?f.postMessage(c,c.transferList):G('Internal error! Worker sent a message "'+e+'" to target pthread '+c.targetThread+", but that thread no longer exists!")}else if("processProxyingQueue"===e)ab(c.queue);else if("spawnThread"===e)Ra(c);else if("cleanupThread"===e)Qa(c.thread);else if("killThread"===e)c=c.thread,e=P.Vb[c],delete P.Vb[c],e.terminate(),Za(c),P.ac.splice(P.ac.indexOf(e),1),e.Ub=0;else if("cancelThread"===e)P.Vb[c.thread].postMessage({cmd:"cancel"});
else if("loaded"===e)a.loaded=!0,b&&b(a),a.$b&&(a.$b(),delete a.$b);else if("print"===e)va("Thread "+c.threadId+": "+c.text);else if("printErr"===e)G("Thread "+c.threadId+": "+c.text);else if("alert"===e)alert("Thread "+c.threadId+": "+c.text);else if("setimmediate"===c.target)a.postMessage(c);else if("onAbort"===e){if(x.onAbort)x.onAbort(c.arg)}else e&&G("worker sent an unknown command "+e);P.Bc=void 0};a.onerror=c=>{G("worker sent an error! "+c.filename+":"+c.lineno+": "+c.message);throw c;};B&&
(a.on("message",function(c){a.onmessage({data:c})}),a.on("error",function(c){a.onerror(c)}),a.on("detachedExit",function(){}));a.postMessage({cmd:"load",urlOrBlob:x.mainScriptUrlOrBlob||_scriptDir,wasmMemory:m,wasmModule:wa})},yc:function(){var a=na("ort-wasm-threaded.worker.js");P.Yb.push(new Worker(a))},Cc:function(){0==P.Yb.length&&(P.yc(),P.Fc(P.Yb[0]));return P.Yb.pop()}};x.PThread=P;function R(a){for(;0<a.length;)a.shift()(x)}function bb(a){var b=S();a=a();U(b);return a}
x.establishStackSpace=function(){var a=$a(),b=r()[a+44>>2>>>0];a=r()[a+48>>2>>>0];cb(b,b-a);U(b)};function Ua(a){if(C)return Q(2,0,a);try{Ta(a)}catch(b){b instanceof E||"unwind"==b||la(1,b)}}var db=[];function V(a){var b=db[a];b||(a>=db.length&&(db.length=a+1),db[a]=b=Ea.get(a));return b}x.invokeEntryPoint=function(a,b){a=V(a)(b);F()?P.nc(a):eb(a)};function fb(a,b){d().set(a,b>>>0)}var gb=[],hb=0,W=0;
function X(a){this.Zb=a;this.Sb=a-24;this.xc=function(b){v()[this.Sb+4>>2>>>0]=b};this.bc=function(){return v()[this.Sb+4>>2>>>0]};this.wc=function(b){v()[this.Sb+8>>2>>>0]=b};this.Dc=function(){return v()[this.Sb+8>>2>>>0]};this.rc=function(){r()[this.Sb>>2>>>0]=0};this.hc=function(b){b=b?1:0;d()[this.Sb+12>>0>>>0]=b};this.uc=function(){return 0!=d()[this.Sb+12>>0>>>0]};this.ic=function(b){b=b?1:0;d()[this.Sb+13>>0>>>0]=b};this.kc=function(){return 0!=d()[this.Sb+13>>0>>>0]};this.fc=function(b,c){this.cc(0);
this.xc(b);this.wc(c);this.rc();this.hc(!1);this.ic(!1)};this.sc=function(){Atomics.add(r(),this.Sb>>2,1)};this.Hc=function(){return 1===Atomics.sub(r(),this.Sb>>2,1)};this.cc=function(b){v()[this.Sb+16>>2>>>0]=b};this.tc=function(){return v()[this.Sb+16>>2>>>0]};this.vc=function(){if(ib(this.bc()))return v()[this.Zb>>2>>>0];var b=this.tc();return 0!==b?b:this.Zb}}function jb(a){return kb((new X(a)).Sb)}function lb(a,b,c,e){return C?Q(3,1,a,b,c,e):mb(a,b,c,e)}
function mb(a,b,c,e){if("undefined"==typeof SharedArrayBuffer)return G("Current environment does not support SharedArrayBuffer, pthreads are not available!"),6;var f=[];if(C&&0===f.length)return lb(a,b,c,e);a={Ic:c,Ub:a,zc:e,Nc:f};return C?(a.Oc="spawnThread",postMessage(a,f),0):Ra(a)}function nb(a,b,c){return C?Q(4,1,a,b,c):0}function ob(a,b){if(C)return Q(5,1,a,b)}function pb(a,b){if(C)return Q(6,1,a,b)}function qb(a,b,c){if(C)return Q(7,1,a,b,c)}function rb(a,b,c){return C?Q(8,1,a,b,c):0}
function sb(a,b){if(C)return Q(9,1,a,b)}function tb(a,b,c){if(C)return Q(10,1,a,b,c)}function ub(a,b,c,e){if(C)return Q(11,1,a,b,c,e)}function vb(a,b,c,e){if(C)return Q(12,1,a,b,c,e)}function wb(a,b,c,e){if(C)return Q(13,1,a,b,c,e)}function xb(a){if(C)return Q(14,1,a)}function yb(a,b){if(C)return Q(15,1,a,b)}function zb(a,b,c){if(C)return Q(16,1,a,b,c)}function ab(a){Atomics.store(r(),a>>2,1);$a()&&Ab(a);Atomics.compareExchange(r(),a>>2,1,0)}x.executeNotifiedProxyingQueue=ab;
function Bb(a){return v()[a>>>2]+4294967296*r()[a+4>>>2]}function Cb(a,b,c,e,f,h){return C?Q(17,1,a,b,c,e,f,h):-52}function Db(a,b,c,e,f,h){if(C)return Q(18,1,a,b,c,e,f,h)}function Eb(a){var b=Ca(a)+1,c=Fb(b);c&&Ba(a,d(),c,b);return c}
function Gb(a,b,c){function e(t){return(t=t.toTimeString().match(/\(([A-Za-z ]+)\)$/))?t[1]:"GMT"}if(C)return Q(19,1,a,b,c);var f=(new Date).getFullYear(),h=new Date(f,0,1),k=new Date(f,6,1);f=h.getTimezoneOffset();var l=k.getTimezoneOffset(),u=Math.max(f,l);r()[a>>2>>>0]=60*u;r()[b>>2>>>0]=Number(f!=l);a=e(h);b=e(k);a=Eb(a);b=Eb(b);l<f?(v()[c>>2>>>0]=a,v()[c+4>>2>>>0]=b):(v()[c>>2>>>0]=b,v()[c+4>>2>>>0]=a)}function Hb(a,b,c){Hb.Ac||(Hb.Ac=!0,Gb(a,b,c))}var Ib,Jb;
Jb=B?()=>{var a=process.hrtime();return 1E3*a[0]+a[1]/1E6}:C?()=>performance.now()-x.__performance_now_clock_drift:()=>performance.now();function Q(a,b){var c=arguments.length-2,e=arguments;return bb(()=>{for(var f=Kb(8*c),h=f>>3,k=0;k<c;k++){var l=e[2+k];ea()[h+k>>>0]=l}return Lb(a,c,f,b)})}var Mb=[],Nb={};
function Ob(){if(!Pb){var a={USER:"web_user",LOGNAME:"web_user",PATH:"/",PWD:"/",HOME:"/home/web_user",LANG:("object"==typeof navigator&&navigator.languages&&navigator.languages[0]||"C").replace("-","_")+".UTF-8",_:ka||"./this.program"},b;for(b in Nb)void 0===Nb[b]?delete a[b]:a[b]=Nb[b];var c=[];for(b in a)c.push(b+"="+a[b]);Pb=c}return Pb}var Pb;
function Qb(a,b){if(C)return Q(20,1,a,b);var c=0;Ob().forEach(function(e,f){var h=b+c;f=v()[a+4*f>>2>>>0]=h;for(h=0;h<e.length;++h)d()[f++>>0>>>0]=e.charCodeAt(h);d()[f>>0>>>0]=0;c+=e.length+1});return 0}function Rb(a,b){if(C)return Q(21,1,a,b);var c=Ob();v()[a>>2>>>0]=c.length;var e=0;c.forEach(function(f){e+=f.length+1});v()[b>>2>>>0]=e;return 0}function Sb(a){return C?Q(22,1,a):52}function Tb(a,b,c,e){return C?Q(23,1,a,b,c,e):52}function Ub(a,b,c,e,f){return C?Q(24,1,a,b,c,e,f):70}
var Xa=[null,[],[]];function Ya(a,b){var c=Xa[a];0===b||10===b?((1===a?va:G)(za(c,0)),c.length=0):c.push(b)}function Vb(a,b,c,e){if(C)return Q(25,1,a,b,c,e);for(var f=0,h=0;h<c;h++){var k=v()[b>>2>>>0],l=v()[b+4>>2>>>0];b+=8;for(var u=0;u<l;u++)Ya(a,q()[k+u>>>0]);f+=l}v()[e>>2>>>0]=f;return 0}var Y=0;
function Wb(){if("object"==typeof crypto&&"function"==typeof crypto.getRandomValues){var a=new Uint8Array(1);return()=>{crypto.getRandomValues(a);return a[0]}}if(B)try{var b=require("crypto");return()=>b.randomBytes(1)[0]}catch(c){}return()=>I("randomDevice")}function Xb(a,b){Xb.lc||(Xb.lc=Wb());for(var c=0;c<b;c++)d()[a+c>>0>>>0]=Xb.lc();return 0}function Yb(a){return 0===a%4&&(0!==a%100||0===a%400)}var Zb=[31,29,31,30,31,30,31,31,30,31,30,31],$b=[31,28,31,30,31,30,31,31,30,31,30,31];
function ac(a){var b=Array(Ca(a)+1);Ba(a,b,0,b.length);return b}
function bc(a,b,c,e){function f(g,w,z){for(g="number"==typeof g?g.toString():g||"";g.length<w;)g=z[0]+g;return g}function h(g,w){return f(g,w,"0")}function k(g,w){function z(T){return 0>T?-1:0<T?1:0}var N;0===(N=z(g.getFullYear()-w.getFullYear()))&&0===(N=z(g.getMonth()-w.getMonth()))&&(N=z(g.getDate()-w.getDate()));return N}function l(g){switch(g.getDay()){case 0:return new Date(g.getFullYear()-1,11,29);case 1:return g;case 2:return new Date(g.getFullYear(),0,3);case 3:return new Date(g.getFullYear(),
0,2);case 4:return new Date(g.getFullYear(),0,1);case 5:return new Date(g.getFullYear()-1,11,31);case 6:return new Date(g.getFullYear()-1,11,30)}}function u(g){var w=g.Wb;for(g=new Date((new Date(g.Xb+1900,0,1)).getTime());0<w;){var z=g.getMonth(),N=(Yb(g.getFullYear())?Zb:$b)[z];if(w>N-g.getDate())w-=N-g.getDate()+1,g.setDate(1),11>z?g.setMonth(z+1):(g.setMonth(0),g.setFullYear(g.getFullYear()+1));else{g.setDate(g.getDate()+w);break}}z=new Date(g.getFullYear()+1,0,4);w=l(new Date(g.getFullYear(),
0,4));z=l(z);return 0>=k(w,g)?0>=k(z,g)?g.getFullYear()+1:g.getFullYear():g.getFullYear()-1}var t=r()[e+40>>2>>>0];e={Lc:r()[e>>2>>>0],Kc:r()[e+4>>2>>>0],dc:r()[e+8>>2>>>0],jc:r()[e+12>>2>>>0],ec:r()[e+16>>2>>>0],Xb:r()[e+20>>2>>>0],Tb:r()[e+24>>2>>>0],Wb:r()[e+28>>2>>>0],Rc:r()[e+32>>2>>>0],Jc:r()[e+36>>2>>>0],Mc:t?Aa(t):""};c=Aa(c);t={"%c":"%a %b %d %H:%M:%S %Y","%D":"%m/%d/%y","%F":"%Y-%m-%d","%h":"%b","%r":"%I:%M:%S %p","%R":"%H:%M","%T":"%H:%M:%S","%x":"%m/%d/%y","%X":"%H:%M:%S","%Ec":"%c","%EC":"%C",
"%Ex":"%m/%d/%y","%EX":"%H:%M:%S","%Ey":"%y","%EY":"%Y","%Od":"%d","%Oe":"%e","%OH":"%H","%OI":"%I","%Om":"%m","%OM":"%M","%OS":"%S","%Ou":"%u","%OU":"%U","%OV":"%V","%Ow":"%w","%OW":"%W","%Oy":"%y"};for(var A in t)c=c.replace(new RegExp(A,"g"),t[A]);var K="Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),J="January February March April May June July August September October November December".split(" ");t={"%a":function(g){return K[g.Tb].substring(0,3)},"%A":function(g){return K[g.Tb]},
"%b":function(g){return J[g.ec].substring(0,3)},"%B":function(g){return J[g.ec]},"%C":function(g){return h((g.Xb+1900)/100|0,2)},"%d":function(g){return h(g.jc,2)},"%e":function(g){return f(g.jc,2," ")},"%g":function(g){return u(g).toString().substring(2)},"%G":function(g){return u(g)},"%H":function(g){return h(g.dc,2)},"%I":function(g){g=g.dc;0==g?g=12:12<g&&(g-=12);return h(g,2)},"%j":function(g){for(var w=0,z=0;z<=g.ec-1;w+=(Yb(g.Xb+1900)?Zb:$b)[z++]);return h(g.jc+w,3)},"%m":function(g){return h(g.ec+
1,2)},"%M":function(g){return h(g.Kc,2)},"%n":function(){return"\n"},"%p":function(g){return 0<=g.dc&&12>g.dc?"AM":"PM"},"%S":function(g){return h(g.Lc,2)},"%t":function(){return"\t"},"%u":function(g){return g.Tb||7},"%U":function(g){return h(Math.floor((g.Wb+7-g.Tb)/7),2)},"%V":function(g){var w=Math.floor((g.Wb+7-(g.Tb+6)%7)/7);2>=(g.Tb+371-g.Wb-2)%7&&w++;if(w)53==w&&(z=(g.Tb+371-g.Wb)%7,4==z||3==z&&Yb(g.Xb)||(w=1));else{w=52;var z=(g.Tb+7-g.Wb-1)%7;(4==z||5==z&&Yb(g.Xb%400-1))&&w++}return h(w,
2)},"%w":function(g){return g.Tb},"%W":function(g){return h(Math.floor((g.Wb+7-(g.Tb+6)%7)/7),2)},"%y":function(g){return(g.Xb+1900).toString().substring(2)},"%Y":function(g){return g.Xb+1900},"%z":function(g){g=g.Jc;var w=0<=g;g=Math.abs(g)/60;return(w?"+":"-")+String("0000"+(g/60*100+g%60)).slice(-4)},"%Z":function(g){return g.Mc},"%%":function(){return"%"}};c=c.replace(/%%/g,"\x00\x00");for(A in t)c.includes(A)&&(c=c.replace(new RegExp(A,"g"),t[A](e)));c=c.replace(/\0\0/g,"%");A=ac(c);if(A.length>
b)return 0;fb(A,a);return A.length-1}P.fc();
var cc=[null,Sa,Ua,lb,nb,ob,pb,qb,rb,sb,tb,ub,vb,wb,xb,yb,zb,Cb,Db,Gb,Qb,Rb,Sb,Tb,Ub,Vb],Pc={b:function(a){return Fb(a+24)+24},n:function(a){a=new X(a);a.uc()||(a.hc(!0),hb--);a.ic(!1);gb.push(a);a.sc();return a.vc()},ma:function(a){G("Unexpected exception thrown, this is not properly supported - aborting");xa=!0;throw a;},x:function(){Z(0);var a=gb.pop();if(a.Hc()&&!a.kc()){var b=a.Dc();b&&V(b)(a.Zb);jb(a.Zb)}W=0},e:function(){var a=W;if(!a)return Y=0;var b=new X(a);b.cc(a);var c=b.bc();if(!c)return Y=
0,a;for(var e=Array.prototype.slice.call(arguments),f=0;f<e.length;f++){var h=e[f];if(0===h||h===c)break;if(dc(h,c,b.Sb+16))return Y=h,a}Y=c;return a},l:function(){var a=W;if(!a)return Y=0;var b=new X(a);b.cc(a);var c=b.bc();if(!c)return Y=0,a;for(var e=Array.prototype.slice.call(arguments),f=0;f<e.length;f++){var h=e[f];if(0===h||h===c)break;if(dc(h,c,b.Sb+16))return Y=h,a}Y=c;return a},h:function(){var a=W;if(!a)return Y=0;var b=new X(a);b.cc(a);var c=b.bc();if(!c)return Y=0,a;for(var e=Array.prototype.slice.call(arguments),
f=0;f<e.length;f++){var h=e[f];if(0===h||h===c)break;if(dc(h,c,b.Sb+16))return Y=h,a}Y=c;return a},t:jb,M:function(){var a=gb.pop();a||I("no exception to throw");var b=a.Zb;a.kc()||(gb.push(a),a.ic(!0),a.hc(!1),hb++);W=b;throw b;},c:function(a,b,c){(new X(a)).fc(b,c);W=a;hb++;throw a;},pa:function(){return hb},Fa:function(a){ec(a,!y,1,!ma);P.pc()},T:function(a){C?postMessage({cmd:"cleanupThread",thread:a}):Qa(a)},xa:mb,j:function(a){W||(W=a);throw a;},H:nb,Ma:ob,ua:pb,wa:qb,oa:rb,Ka:sb,Ca:tb,Ja:ub,
V:vb,va:wb,sa:xb,La:yb,ta:zb,Ta:function(){},X:function(){I("To use dlopen, you need enable dynamic linking, see https://github.com/emscripten-core/emscripten/wiki/Linking")},Ua:function(){I("To use dlopen, you need enable dynamic linking, see https://github.com/emscripten-core/emscripten/wiki/Linking")},W:function(){return Date.now()},ya:function(){return 2097152},Oa:function(){return!0},za:function(a,b,c,e){if(a==b)setTimeout(()=>ab(e));else if(C)postMessage({targetThread:a,cmd:"processProxyingQueue",
queue:e});else{a=P.Vb[a];if(!a)return;a.postMessage({cmd:"processProxyingQueue",queue:e})}return 1},Ea:function(){return-1},Pa:function(a,b){a=new Date(1E3*Bb(a));r()[b>>2>>>0]=a.getUTCSeconds();r()[b+4>>2>>>0]=a.getUTCMinutes();r()[b+8>>2>>>0]=a.getUTCHours();r()[b+12>>2>>>0]=a.getUTCDate();r()[b+16>>2>>>0]=a.getUTCMonth();r()[b+20>>2>>>0]=a.getUTCFullYear()-1900;r()[b+24>>2>>>0]=a.getUTCDay();a=(a.getTime()-Date.UTC(a.getUTCFullYear(),0,1,0,0,0,0))/864E5|0;r()[b+28>>2>>>0]=a},Qa:function(a,b){a=
new Date(1E3*Bb(a));r()[b>>2>>>0]=a.getSeconds();r()[b+4>>2>>>0]=a.getMinutes();r()[b+8>>2>>>0]=a.getHours();r()[b+12>>2>>>0]=a.getDate();r()[b+16>>2>>>0]=a.getMonth();r()[b+20>>2>>>0]=a.getFullYear()-1900;r()[b+24>>2>>>0]=a.getDay();var c=new Date(a.getFullYear(),0,1),e=(a.getTime()-c.getTime())/864E5|0;r()[b+28>>2>>>0]=e;r()[b+36>>2>>>0]=-(60*a.getTimezoneOffset());e=(new Date(a.getFullYear(),6,1)).getTimezoneOffset();c=c.getTimezoneOffset();a=(e!=c&&a.getTimezoneOffset()==Math.min(c,e))|0;r()[b+
32>>2>>>0]=a},Ra:function(a){var b=new Date(r()[a+20>>2>>>0]+1900,r()[a+16>>2>>>0],r()[a+12>>2>>>0],r()[a+8>>2>>>0],r()[a+4>>2>>>0],r()[a>>2>>>0],0),c=r()[a+32>>2>>>0],e=b.getTimezoneOffset(),f=new Date(b.getFullYear(),0,1),h=(new Date(b.getFullYear(),6,1)).getTimezoneOffset(),k=f.getTimezoneOffset(),l=Math.min(k,h);0>c?r()[a+32>>2>>>0]=Number(h!=k&&l==e):0<c!=(l==e)&&(h=Math.max(k,h),b.setTime(b.getTime()+6E4*((0<c?l:h)-e)));r()[a+24>>2>>>0]=b.getDay();c=(b.getTime()-f.getTime())/864E5|0;r()[a+28>>
2>>>0]=c;r()[a>>2>>>0]=b.getSeconds();r()[a+4>>2>>>0]=b.getMinutes();r()[a+8>>2>>>0]=b.getHours();r()[a+12>>2>>>0]=b.getDate();r()[a+16>>2>>>0]=b.getMonth();return b.getTime()/1E3|0},Aa:Cb,Ba:Db,Sa:Hb,y:function(){I("")},U:function(){if(!B&&!y){var a="Blocking on the main thread is very dangerous, see https://emscripten.org/docs/porting/pthreads.html#blocking-on-the-main-browser-thread";Ib||(Ib={});Ib[a]||(Ib[a]=1,B&&(a="warning: "+a),G(a))}},ra:function(){return 4294901760},B:Jb,Ia:function(a,b,
c){q().copyWithin(a>>>0,b>>>0,b+c>>>0)},F:function(){return B?require("os").cpus().length:navigator.hardwareConcurrency},Da:function(a,b,c){Mb.length=b;c>>=3;for(var e=0;e<b;e++)Mb[e]=ea()[c+e>>>0];return(0>a?Pa[-a-1]:cc[a]).apply(null,Mb)},qa:function(a){var b=q().length;a>>>=0;if(a<=b||4294901760<a)return!1;for(var c=1;4>=c;c*=2){var e=b*(1+.2/c);e=Math.min(e,a+100663296);var f=Math;e=Math.max(a,e);f=f.min.call(f,4294901760,e+(65536-e%65536)%65536);a:{try{m.grow(f-n.byteLength+65535>>>16);p(m.buffer);
var h=1;break a}catch(k){}h=void 0}if(h)return!0}return!1},Na:function(){throw"unwind";},Ga:Qb,Ha:Rb,J:Ta,I:Sb,S:Tb,ga:Ub,R:Vb,d:function(){return Y},na:Xb,ia:fc,ja:gc,K:hc,f:ic,P:jc,Q:kc,k:lc,p:mc,q:nc,N:oc,s:pc,w:qc,L:rc,E:sc,aa:tc,_:uc,Z:vc,ca:wc,$:xc,ba:yc,Y:zc,g:Ac,r:Bc,i:Cc,ha:Dc,m:Ec,v:Fc,u:Gc,O:Hc,A:Ic,ka:Jc,C:Kc,D:Lc,fa:Mc,da:Nc,ea:Oc,o:function(a){return a},a:m||x.wasmMemory,G:function(a){Y=a},la:bc,z:function(a,b,c,e){return bc(a,b,c,e)}};
(function(){function a(f,h){x.asm=f.exports;P.qc.push(x.asm.sb);Ea=x.asm.ub;Ga.unshift(x.asm.Va);wa=h;C||(L--,x.monitorRunDependencies&&x.monitorRunDependencies(L),0==L&&(null!==La&&(clearInterval(La),La=null),M&&(f=M,M=null,f())))}function b(f){a(f.instance,f.module)}function c(f){return Oa().then(function(h){return WebAssembly.instantiate(h,e)}).then(function(h){return h}).then(f,function(h){G("failed to asynchronously prepare wasm: "+h);I(h)})}var e={a:Pc};C||(L++,x.monitorRunDependencies&&x.monitorRunDependencies(L));
if(x.instantiateWasm)try{return x.instantiateWasm(e,a)}catch(f){return G("Module.instantiateWasm callback failed with error: "+f),!1}(function(){return H||"function"!=typeof WebAssembly.instantiateStreaming||Ma()||O.startsWith("file://")||B||"function"!=typeof fetch?c(b):fetch(O,{credentials:"same-origin"}).then(function(f){return WebAssembly.instantiateStreaming(f,e).then(b,function(h){G("wasm streaming compile failed: "+h);G("falling back to ArrayBuffer instantiation");return c(b)})})})().catch(ia);
return{}})();x.___wasm_call_ctors=function(){return(x.___wasm_call_ctors=x.asm.Va).apply(null,arguments)};x._OrtInit=function(){return(x._OrtInit=x.asm.Wa).apply(null,arguments)};x._OrtCreateSessionOptions=function(){return(x._OrtCreateSessionOptions=x.asm.Xa).apply(null,arguments)};x._OrtAppendExecutionProvider=function(){return(x._OrtAppendExecutionProvider=x.asm.Ya).apply(null,arguments)};x._OrtAddSessionConfigEntry=function(){return(x._OrtAddSessionConfigEntry=x.asm.Za).apply(null,arguments)};
x._OrtReleaseSessionOptions=function(){return(x._OrtReleaseSessionOptions=x.asm._a).apply(null,arguments)};x._OrtCreateSession=function(){return(x._OrtCreateSession=x.asm.$a).apply(null,arguments)};x._OrtReleaseSession=function(){return(x._OrtReleaseSession=x.asm.ab).apply(null,arguments)};x._OrtGetInputCount=function(){return(x._OrtGetInputCount=x.asm.bb).apply(null,arguments)};x._OrtGetOutputCount=function(){return(x._OrtGetOutputCount=x.asm.cb).apply(null,arguments)};
x._OrtGetInputName=function(){return(x._OrtGetInputName=x.asm.db).apply(null,arguments)};x._OrtGetOutputName=function(){return(x._OrtGetOutputName=x.asm.eb).apply(null,arguments)};x._OrtFree=function(){return(x._OrtFree=x.asm.fb).apply(null,arguments)};x._OrtCreateTensor=function(){return(x._OrtCreateTensor=x.asm.gb).apply(null,arguments)};x._OrtGetTensorData=function(){return(x._OrtGetTensorData=x.asm.hb).apply(null,arguments)};
x._OrtReleaseTensor=function(){return(x._OrtReleaseTensor=x.asm.ib).apply(null,arguments)};x._OrtCreateRunOptions=function(){return(x._OrtCreateRunOptions=x.asm.jb).apply(null,arguments)};x._OrtAddRunConfigEntry=function(){return(x._OrtAddRunConfigEntry=x.asm.kb).apply(null,arguments)};x._OrtReleaseRunOptions=function(){return(x._OrtReleaseRunOptions=x.asm.lb).apply(null,arguments)};x._OrtRun=function(){return(x._OrtRun=x.asm.mb).apply(null,arguments)};
x._OrtEndProfiling=function(){return(x._OrtEndProfiling=x.asm.nb).apply(null,arguments)};var $a=x._pthread_self=function(){return($a=x._pthread_self=x.asm.ob).apply(null,arguments)},Fb=x._malloc=function(){return(Fb=x._malloc=x.asm.pb).apply(null,arguments)},kb=x._free=function(){return(kb=x._free=x.asm.qb).apply(null,arguments)},Wa=x._fflush=function(){return(Wa=x._fflush=x.asm.rb).apply(null,arguments)};x.__emscripten_tls_init=function(){return(x.__emscripten_tls_init=x.asm.sb).apply(null,arguments)};
var Va=x.___funcs_on_exit=function(){return(Va=x.___funcs_on_exit=x.asm.tb).apply(null,arguments)},ec=x.__emscripten_thread_init=function(){return(ec=x.__emscripten_thread_init=x.asm.vb).apply(null,arguments)};x.__emscripten_thread_crashed=function(){return(x.__emscripten_thread_crashed=x.asm.wb).apply(null,arguments)};
var Lb=x._emscripten_run_in_main_runtime_thread_js=function(){return(Lb=x._emscripten_run_in_main_runtime_thread_js=x.asm.xb).apply(null,arguments)},Ab=x.__emscripten_proxy_execute_task_queue=function(){return(Ab=x.__emscripten_proxy_execute_task_queue=x.asm.yb).apply(null,arguments)},Za=x.__emscripten_thread_free_data=function(){return(Za=x.__emscripten_thread_free_data=x.asm.zb).apply(null,arguments)},eb=x.__emscripten_thread_exit=function(){return(eb=x.__emscripten_thread_exit=x.asm.Ab).apply(null,
arguments)},Z=x._setThrew=function(){return(Z=x._setThrew=x.asm.Bb).apply(null,arguments)},cb=x._emscripten_stack_set_limits=function(){return(cb=x._emscripten_stack_set_limits=x.asm.Cb).apply(null,arguments)},S=x.stackSave=function(){return(S=x.stackSave=x.asm.Db).apply(null,arguments)},U=x.stackRestore=function(){return(U=x.stackRestore=x.asm.Eb).apply(null,arguments)},Kb=x.stackAlloc=function(){return(Kb=x.stackAlloc=x.asm.Fb).apply(null,arguments)},dc=x.___cxa_can_catch=function(){return(dc=x.___cxa_can_catch=
x.asm.Gb).apply(null,arguments)},ib=x.___cxa_is_pointer_type=function(){return(ib=x.___cxa_is_pointer_type=x.asm.Hb).apply(null,arguments)},Qc=x.dynCall_j=function(){return(Qc=x.dynCall_j=x.asm.Ib).apply(null,arguments)},Rc=x.dynCall_iiiiij=function(){return(Rc=x.dynCall_iiiiij=x.asm.Jb).apply(null,arguments)},Sc=x.dynCall_jii=function(){return(Sc=x.dynCall_jii=x.asm.Kb).apply(null,arguments)},Tc=x.dynCall_viiiiij=function(){return(Tc=x.dynCall_viiiiij=x.asm.Lb).apply(null,arguments)},Uc=x.dynCall_vjji=
function(){return(Uc=x.dynCall_vjji=x.asm.Mb).apply(null,arguments)},Vc=x.dynCall_viiijjjii=function(){return(Vc=x.dynCall_viiijjjii=x.asm.Nb).apply(null,arguments)},Wc=x.dynCall_iij=function(){return(Wc=x.dynCall_iij=x.asm.Ob).apply(null,arguments)},Xc=x.dynCall_ji=function(){return(Xc=x.dynCall_ji=x.asm.Pb).apply(null,arguments)},Yc=x.dynCall_iiiiiij=function(){return(Yc=x.dynCall_iiiiiij=x.asm.Qb).apply(null,arguments)},Zc=x.dynCall_iiij=function(){return(Zc=x.dynCall_iiij=x.asm.Rb).apply(null,
arguments)};function ic(a,b){var c=S();try{return V(a)(b)}catch(e){U(c);if(e!==e+0)throw e;Z(1,0)}}function Bc(a,b){var c=S();try{V(a)(b)}catch(e){U(c);if(e!==e+0)throw e;Z(1,0)}}function Cc(a,b,c){var e=S();try{V(a)(b,c)}catch(f){U(e);if(f!==f+0)throw f;Z(1,0)}}function lc(a,b,c){var e=S();try{return V(a)(b,c)}catch(f){U(e);if(f!==f+0)throw f;Z(1,0)}}function mc(a,b,c,e){var f=S();try{return V(a)(b,c,e)}catch(h){U(f);if(h!==h+0)throw h;Z(1,0)}}
function qc(a,b,c,e,f,h,k){var l=S();try{return V(a)(b,c,e,f,h,k)}catch(u){U(l);if(u!==u+0)throw u;Z(1,0)}}function Ac(a){var b=S();try{V(a)()}catch(c){U(b);if(c!==c+0)throw c;Z(1,0)}}function pc(a,b,c,e,f,h){var k=S();try{return V(a)(b,c,e,f,h)}catch(l){U(k);if(l!==l+0)throw l;Z(1,0)}}function nc(a,b,c,e,f){var h=S();try{return V(a)(b,c,e,f)}catch(k){U(h);if(k!==k+0)throw k;Z(1,0)}}function Ec(a,b,c,e){var f=S();try{V(a)(b,c,e)}catch(h){U(f);if(h!==h+0)throw h;Z(1,0)}}
function Gc(a,b,c,e,f,h){var k=S();try{V(a)(b,c,e,f,h)}catch(l){U(k);if(l!==l+0)throw l;Z(1,0)}}function Fc(a,b,c,e,f){var h=S();try{V(a)(b,c,e,f)}catch(k){U(h);if(k!==k+0)throw k;Z(1,0)}}function Ic(a,b,c,e,f,h,k,l){var u=S();try{V(a)(b,c,e,f,h,k,l)}catch(t){U(u);if(t!==t+0)throw t;Z(1,0)}}function kc(a,b,c){var e=S();try{return V(a)(b,c)}catch(f){U(e);if(f!==f+0)throw f;Z(1,0)}}function jc(a,b,c){var e=S();try{return V(a)(b,c)}catch(f){U(e);if(f!==f+0)throw f;Z(1,0)}}
function Jc(a,b,c,e,f,h,k,l,u){var t=S();try{V(a)(b,c,e,f,h,k,l,u)}catch(A){U(t);if(A!==A+0)throw A;Z(1,0)}}function Hc(a,b,c,e,f,h,k){var l=S();try{V(a)(b,c,e,f,h,k)}catch(u){U(l);if(u!==u+0)throw u;Z(1,0)}}function oc(a,b,c,e,f,h){var k=S();try{return V(a)(b,c,e,f,h)}catch(l){U(k);if(l!==l+0)throw l;Z(1,0)}}function rc(a,b,c,e,f,h,k,l){var u=S();try{return V(a)(b,c,e,f,h,k,l)}catch(t){U(u);if(t!==t+0)throw t;Z(1,0)}}
function sc(a,b,c,e,f,h,k,l,u,t,A,K){var J=S();try{return V(a)(b,c,e,f,h,k,l,u,t,A,K)}catch(g){U(J);if(g!==g+0)throw g;Z(1,0)}}function Kc(a,b,c,e,f,h,k,l,u,t,A){var K=S();try{V(a)(b,c,e,f,h,k,l,u,t,A)}catch(J){U(K);if(J!==J+0)throw J;Z(1,0)}}function Lc(a,b,c,e,f,h,k,l,u,t,A,K,J,g,w,z){var N=S();try{V(a)(b,c,e,f,h,k,l,u,t,A,K,J,g,w,z)}catch(T){U(N);if(T!==T+0)throw T;Z(1,0)}}function hc(a){var b=S();try{return V(a)()}catch(c){U(b);if(c!==c+0)throw c;Z(1,0)}}
function gc(a,b,c){var e=S();try{return V(a)(b,c)}catch(f){U(e);if(f!==f+0)throw f;Z(1,0)}}function fc(a,b,c){var e=S();try{return V(a)(b,c)}catch(f){U(e);if(f!==f+0)throw f;Z(1,0)}}function Dc(a,b,c,e){var f=S();try{V(a)(b,c,e)}catch(h){U(f);if(h!==h+0)throw h;Z(1,0)}}function Mc(a,b,c,e,f,h,k,l){var u=S();try{Tc(a,b,c,e,f,h,k,l)}catch(t){U(u);if(t!==t+0)throw t;Z(1,0)}}function Oc(a,b,c,e,f,h){var k=S();try{Uc(a,b,c,e,f,h)}catch(l){U(k);if(l!==l+0)throw l;Z(1,0)}}
function Nc(a,b,c,e,f,h,k,l,u,t,A,K){var J=S();try{Vc(a,b,c,e,f,h,k,l,u,t,A,K)}catch(g){U(J);if(g!==g+0)throw g;Z(1,0)}}function wc(a,b,c,e){var f=S();try{return Wc(a,b,c,e)}catch(h){U(f);if(h!==h+0)throw h;Z(1,0)}}function yc(a,b){var c=S();try{return Xc(a,b)}catch(e){U(c);if(e!==e+0)throw e;Z(1,0)}}function tc(a,b,c,e,f,h,k,l){var u=S();try{return Yc(a,b,c,e,f,h,k,l)}catch(t){U(u);if(t!==t+0)throw t;Z(1,0)}}function xc(a){var b=S();try{return Qc(a)}catch(c){U(b);if(c!==c+0)throw c;Z(1,0)}}
function uc(a,b,c,e,f,h,k){var l=S();try{return Rc(a,b,c,e,f,h,k)}catch(u){U(l);if(u!==u+0)throw u;Z(1,0)}}function vc(a,b,c,e,f){var h=S();try{return Zc(a,b,c,e,f)}catch(k){U(h);if(k!==k+0)throw k;Z(1,0)}}function zc(a,b,c){var e=S();try{return Sc(a,b,c)}catch(f){U(e);if(f!==f+0)throw f;Z(1,0)}}x.UTF8ToString=Aa;x.stringToUTF8=function(a,b,c){return Ba(a,q(),b,c)};x.lengthBytesUTF8=Ca;x.keepRuntimeAlive=F;x.wasmMemory=m;x.stackSave=S;x.stackRestore=U;x.stackAlloc=Kb;x.ExitStatus=E;x.PThread=P;var $c;
M=function ad(){$c||bd();$c||(M=ad)};
function bd(){function a(){if(!$c&&($c=!0,x.calledRun=!0,!xa)){C||R(Ga);ha(x);if(x.onRuntimeInitialized)x.onRuntimeInitialized();if(!C){if(x.postRun)for("function"==typeof x.postRun&&(x.postRun=[x.postRun]);x.postRun.length;){var b=x.postRun.shift();Ia.unshift(b)}R(Ia)}}}if(!(0<L))if(C)ha(x),C||R(Ga),postMessage({cmd:"loaded"});else{if(x.preRun)for("function"==typeof x.preRun&&(x.preRun=[x.preRun]);x.preRun.length;)Ka();R(Fa);0<L||(x.setStatus?(x.setStatus("Running..."),setTimeout(function(){setTimeout(function(){x.setStatus("")},
1);a()},1)):a())}}if(x.preInit)for("function"==typeof x.preInit&&(x.preInit=[x.preInit]);0<x.preInit.length;)x.preInit.pop()();bd();


  return ortWasmThreaded.ready
}
);
})();
if (typeof exports === 'object' && typeof module === 'object')
  module.exports = ortWasmThreaded;
else if (typeof define === 'function' && define['amd'])
  define([], function() { return ortWasmThreaded; });
else if (typeof exports === 'object')
  exports["ortWasmThreaded"] = ortWasmThreaded;
